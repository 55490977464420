// vendors
import React from 'react';
import PropTypes from 'prop-types';

const IconCart = ({ count, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 26.5 35.5'
    style={{ fill: 'currentColor', color: 'currentColor' }}
    {...props}
  >
    <path
      d='M21.3,10.2V8.1c0-4.5-3.6-8.1-8.1-8.1C8.8,0,5.2,3.6,5.2,8.1v2.1H0v25.3h26.5V10.2H21.3z M7.2,8.1
			C7.2,4.7,9.9,2,13.3,2s6.1,2.7,6.1,6.1v2.1H7.2V8.1z M24.5,33.5H2V12.2h22.5V33.5z'
    />
    {count > 0 && (
      <text
        transform='matrix(1 0 0 1 8.4466 28.1568)'
        fontFamily='IBMPlexSans-Bold'
        fontSize='16'
      >
        {count}
      </text>
    )}
  </svg>
);

IconCart.propTypes = {
  count: PropTypes.number,
};

IconCart.defaultProps = {
  count: 0,
};

export default IconCart;
