// vendors
import React from 'react';

const IconPen = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.86 42.41' {...props}>
    <rect x='1.92' y='8' width='7.01' height='2' />
    <path d='M5.4,1V0A5.23,5.23,0,0,0,1.56,1.7,5.88,5.88,0,0,0,0,5.72H0V34.1a1,1,0,0,0,.14.51L4.7,42a1,1,0,0,0,.82.46H5.6a1,1,0,0,0,.83-.48l4.3-7.34a1.05,1.05,0,0,0,.13-.49V5.72a5.88,5.88,0,0,0-1.56-4A5.24,5.24,0,0,0,5.46,0H5.4V1.92h.06A3.36,3.36,0,0,1,7.89,3a4,4,0,0,1,1,2.71V33.84L4.77,41l.83.48v-1H5.52v1l.82-.5L1.92,33.83V5.72A4,4,0,0,1,3,3,3.36,3.36,0,0,1,5.4,1.92Z' />
  </svg>
);

export default IconPen;
