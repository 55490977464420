// vendors
import React from 'react';

const IconInstagram = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.2 31.2' {...props}>
    <path d='M15.6 2.8l6.3.1a8.7 8.7 0 012.9.5 4.8 4.8 0 011.8 1.2 4.8 4.8 0 011.2 1.8 8.5 8.5 0 01.5 2.9v12.6a8.4 8.4 0 01-.5 2.9 5 5 0 01-3 3 8.4 8.4 0 01-3 .5l-6.2.1H9.3a8.5 8.5 0 01-3-.6 4.8 4.8 0 01-1.7-1.2 4.8 4.8 0 01-1.2-1.8 8.7 8.7 0 01-.5-2.9v-6.3-6.3a8.8 8.8 0 01.5-2.9 4.7 4.7 0 011.2-1.8 4.7 4.7 0 011.8-1.2A8.8 8.8 0 019.3 3h6.3m0-2.9H9.2a11.4 11.4 0 00-3.8.8 7.7 7.7 0 00-2.8 1.8A7.7 7.7 0 00.8 5.4a11.4 11.4 0 00-.7 3.8L0 15.6V22a11.4 11.4 0 00.8 3.8 7.5 7.5 0 001.8 2.7 7.6 7.6 0 002.8 1.8 11.4 11.4 0 003.8.8H22a11.4 11.4 0 003.8-.8 8 8 0 004.6-4.5A11.4 11.4 0 0031 22l.1-6.4V9a11.4 11.4 0 00-.8-3.7 7.6 7.6 0 00-1.8-2.8A7.5 7.5 0 0025.8.8 11.4 11.4 0 0022 .1L15.6 0z' />
    <path d='M15.6 7.6a8 8 0 108 8 8 8 0 00-8-8zm0 13.2a5.2 5.2 0 115.2-5.2 5.2 5.2 0 01-5.2 5.2z' />
    <circle cx='23.9' cy='7.3' r='1.9' />
  </svg>
);

export default IconInstagram;
