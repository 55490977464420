// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { hideVisually } from 'polished';
import LogoSVG from '../../../images/vector-logoMaxilloQuebec.svg';
import LogoConcatSVG from '../../../images/vector-logoMaxilloQuebecConcat.svg';
import { ImgConcat, ImgFull, LogoWrapperStyle } from './Logo.styles';

const Logo = ({ isHomePage }) => {
  if (isHomePage) {
    return (
      <h1 css={LogoWrapperStyle}>
        <span css={hideVisually}>Maxillo Québec</span>

        <ImgConcat src={LogoConcatSVG} alt='' role='presentation' />

        <ImgFull src={LogoSVG} alt='' role='presentation' />
      </h1>
    );
  }

  return (
    <Link
      css={LogoWrapperStyle}
      to='/'
      activeClassName='active'
      partiallyActive
    >
      <span css={hideVisually}>Accueil</span>

      <ImgConcat src={LogoConcatSVG} alt='' role='presentation' />

      <ImgFull src={LogoSVG} alt='' role='presentation' />
    </Link>
  );
};

Logo.propTypes = {
  isHomePage: PropTypes.bool,
};

Logo.defaultProps = {
  isHomePage: false,
};

export default Logo;
