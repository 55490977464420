import styled from 'styled-components';
import { rem } from 'polished';
import colors from '../../styles/colors';
import { speed, easing } from '../../styles/animation';

export const Bread = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  will-change: transform, opacity;
`;

export const Crust = styled.span`
  display: block;
  width: ${rem(26)};
  height: ${rem(3)};
  margin: auto;

  background-color: ${colors.white};

  will-change: transform;
`;

export const StyledButton = styled.button`
  position: relative;

  width: ${rem(48)};
  height: ${rem(48)};

  background: none;
  border: 0;
  cursor: pointer;

  ${Crust} {
    transition: transform
      ${(props) => (props.open ? speed.fast : speed.superslow)}
      ${easing.inOutBack} ${(props) => (props.open ? '0ms' : speed.fast)};

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  ${Bread} {
    transition: transform
        ${(props) => (props.open ? speed.fast : speed.default)}
        ${(props) => (props.open ? speed.fast : '0ms')},
      opacity 0ms ${speed.fast};

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  appearance: none;
`;
