// vendors
import React from 'react';
import {
  Container,
  Wrapper,
  Card,
  Paragraph,
  Intro,
  buttonStyles,
} from './CallToActionSection.styles';
import Button from '../../Button';

const CallToActionSection = () => {
  return (
    <Container>
      <Wrapper>
        <Card>
          <Paragraph>
            <Intro>J’ai besoin —</Intro>
            de conseils avant-après une&nbsp;chirurgie
          </Paragraph>

          <Button to='/espace-patient' outlined primary css={[buttonStyles]}>
            Voir l&apos;espace patient
          </Button>
        </Card>

        <Card>
          <Paragraph>
            <Intro>J’ai besoin —</Intro>
            de compléter le questionnaire médical avant ma&nbsp;visite
          </Paragraph>

          <Button
            outlined
            primary
            css={[buttonStyles]}
            to='/questionnaire-medical'
          >
            Remplir le formulaire
          </Button>
        </Card>

        <Card>
          <Paragraph>
            <Intro>J’ai besoin —</Intro>
            de référer un patient chez Maxillo&nbsp;Québec
          </Paragraph>

          <Button
            outlined
            primary
            css={[buttonStyles]}
            to='/referer-un-patient'
          >
            Remplir le formulaire
          </Button>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default CallToActionSection;
