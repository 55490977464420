// vendors
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import colors from '../../../styles/colors';
import breakpoints from '../../../styles/breakpoints';
import breakpointsRange from '../../../utils/breakpointsRange';
import { lessThan } from '../../../utils/mediaQuery';
import { speed, easing } from '../../../styles/animation';

export const Container = styled.div`
  padding: 28px;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [28, 104] },
      { prop: 'paddingRight', sizes: [28, 40, 40, 0] },
      { prop: 'paddingBottom', sizes: [28, 104] },
      { prop: 'paddingLeft', sizes: [28, 40, 40, 0] },
    ],
    breakpoints.spacings
  )};

  color: ${colors.azure};

  background-color: ${transparentize(0.87, colors.azure)};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 1440px;
  margin: auto;

  ${lessThan(768)} {
    flex-flow: column;
    max-width: 440px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1 1;
  flex-flow: column;

  max-width: 440px;

  transition: background-color ${speed.superfast} ${easing.inQuint};

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [36, 88] },
      { prop: 'paddingRight', sizes: [28, 28, 40, 40] },
      { prop: 'paddingBottom', sizes: [36, 64] },
      { prop: 'paddingLeft', sizes: [28, 28, 40, 40] },
    ],
    breakpoints.spacings
  )};

  :hover,
  :focus {
    background-color: ${colors.white};

    transition: background-color ${speed.fast} ${easing.outQuint};

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const Paragraph = styled.p`
  flex-grow: 1;

  margin-top: 0;
  margin-bottom: 40px;

  line-height: ${25 / 20};

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [20, 24, 30] }],
    breakpoints.fonts
  )}
`;

export const Intro = styled.span`
  display: block;
  margin-bottom: 1em;
`;

export const buttonStyles = css`
  width: 100%;
  min-width: initial !important;
  max-width: 286px;

  padding-right: 0;
  padding-left: 0;
`;
