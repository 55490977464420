import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'styled-components/macro';
import { rem, hideVisually } from 'polished';
import InputMask from 'react-input-mask';
import '@wldlab/yup-phone';
import '@wldlab/yup-postal-code';

import randomString from '../../utils/randomString';
import theme from '../../styles/theme';
import { lessThan, greaterThan } from '../../utils/mediaQuery';

import Modal from '../Modal';
import TextInputField from '../TextInputField';
import TextAreaField from '../TextAreaField';
import Button from '../Button';

import {
  Stack,
  Heading,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../Form/Form.styles';
import Container from './ContactForm.styles';
import { useContactForm } from '../../contexts/contactFormContext';
import beforeMaskedStateChange from '../../utils/beforeMaskedStateChange';

import CliniquesFormSection from '../Form/CliniquesFormSection';
import SpecialistsFormSection from '../Form/SpecialistsFormSection';
import { useNotificationsCenter } from '../../contexts/notificationsCenterContext';
import { bannerType } from '../NotificationsCenter/Banner';
import { PrivacyPolicyCheckbox } from '../PrivacyPolicy';

const API_URL = process.env.GATSBY_API_URL || 'https://api.maxilloquebec.com';

const validateSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Insérez votre prénom')
    .min(2, 'Le prénom inséré est trop court')
    .max(50, 'Le prénom inséré est trop long'),
  lastName: Yup.string()
    .required('Insérez votre nom')
    .min(2, 'Le nom inséré est trop court')
    .max(50, 'Le nom inséré est trop long'),
  address: Yup.string().required('Insérez votre adresse postale'),
  city: Yup.string().required('Insérez votre ville de résidence'),
  postalCode: Yup.string()
    .required('Insérez votre code postal')
    .postalCode('CA', 'Insérez un code postal valide'),
  phone: Yup.string()
    .required('Insérez votre numéro de téléphone')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  email: Yup.string().email('Insérez une adresse courriel valide'),
  dateBirth: Yup.date()
    .required('Insérez votre date de naissance')
    .typeError('Insérez une date valide'),
  hasConfirmed: Yup.boolean()
    .required(
      'Veuillez cocher afin de confirmer que ces informations sont bien les vôtres.'
    )
    .oneOf(
      [true],
      'Veuillez cocher afin de confirmer que ces informations sont bien les vôtres.'
    ),
});

const ContactForm = ({ id, ...rest }) => {
  const notificationIdRef = useRef(null);
  const { addNotification, removeNotification } = useNotificationsCenter();
  const {
    isOpen,
    close,
    preSelectedSpecialist,
    preSelectedClinique,
  } = useContactForm();

  const handleClose = () => {
    close();
  };

  const initialValues = {
    clinic: preSelectedClinique || '',
    specialists: preSelectedSpecialist ? [preSelectedSpecialist] : [],
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    email: '',
    description: '',
    dateBirth: '',
    hasConfirmed: undefined,
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    if (notificationIdRef.current) {
      removeNotification(notificationIdRef.current);
    }

    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    const myInit = {
      method: 'POST',
      mode: 'cors',
      body: formData,
    };

    try {
      const response = await fetch(
        `${API_URL}/v1/form/contact-form`,
        myInit
      ).then((res) => {
        if (res.status > 400) {
          throw new Error(res.statusText);
        }

        return res.json();
      });

      if (response.success) {
        notificationIdRef.current = addNotification(
          'Votre formulaire a bien été envoyé. Nous prendrons contact avec vous prochainement.',
          {
            autoDismiss: true,
            type: bannerType.success,
          }
        );

        close();
      }

      if (response.inner && response.inner.length > 0) {
        response.inner.forEach(({ message, path }) =>
          actions.setFieldError(path, message)
        );

        const notificationMessage =
          response.inner.length > 1
            ? 'Il y a plusieurs champs incomplets.'
            : response.message;

        notificationIdRef.current = addNotification(notificationMessage, {
          autoDismiss: true,
          type: bannerType.error,
        });
      }

      actions.setSubmitting(false);
    } catch (error) {
      addNotification(
        'Une erreur s’est produite. Le formulaire n’a pu être envoyé. Veuillez essayer de nouveau.',
        {
          autoDismiss: true,
          type: bannerType.error,
        }
      );

      window.Sentry.captureException(error);

      console.error(error);
    }
  };

  const customId = id || randomString();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      aria={{ labelledby: customId }}
      closeTimeoutMS={500}
      {...rest}
    >
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit: formikHandleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
            isValidating,
            setFieldValue,
          }) => (
            <form onSubmit={formikHandleSubmit}>
              <Stack>
                <Heading id={customId}>Formulaire de contact</Heading>

                <Fieldset>
                  <Legend>1. Choisir votre clinique</Legend>

                  <FieldsetContent>
                    <CliniquesFormSection />
                  </FieldsetContent>
                </Fieldset>

                <Fieldset>
                  <Legend>2. Choisir votre spécialiste</Legend>

                  <FieldsetContent>
                    <SpecialistsFormSection />
                  </FieldsetContent>
                </Fieldset>

                <Fieldset>
                  <Legend>3. Transmettre vos coordonnées</Legend>

                  <FieldsetContent>
                    <div
                      css={`
                        display: grid;
                        grid-gap: ${rem(32)};
                        grid-template-columns: 1fr 1fr;
                        align-items: baseline;

                        ${lessThan(theme.breakpoints.fonts[0])} {
                          grid-template-columns: 1fr;
                        }
                      `}
                    >
                      <TextInputField
                        label='Nom*'
                        name='lastName'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.lastName && touched.lastName}
                        helperText={touched.lastName ? errors.lastName : ''}
                      />

                      <TextInputField
                        label='Prénom*'
                        name='firstName'
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.firstName && touched.firstName}
                        helperText={touched.firstName ? errors.firstName : ''}
                      />

                      <TextInputField
                        label='Adresse*'
                        name='address'
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.address && touched.address}
                        helperText={touched.address ? errors.address : ''}
                        css={`
                          ${greaterThan(theme.breakpoints.fonts[0])} {
                            grid-column: 1 / span 2;
                          }
                        `}
                      />

                      <TextInputField
                        label='Ville*'
                        name='city'
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.city && touched.city}
                        helperText={touched.city ? errors.city : ''}
                      />

                      <InputMask
                        mask='a9a 9a9'
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        name='postalCode'
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.postalCode && touched.postalCode}
                        disabled={false}
                      >
                        <TextInputField
                          label='Code postal*'
                          helperText={
                            touched.postalCode ? errors.postalCode : ''
                          }
                        />
                      </InputMask>

                      <InputMask
                        mask='(999) 999-9999'
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        type='phone'
                        name='phone'
                        value={values.phone}
                        error={errors.phone && touched.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                      >
                        <TextInputField
                          label='Téléphone*'
                          helperText={touched.phone ? errors.phone : ''}
                        />
                      </InputMask>

                      <TextInputField
                        label='Courriel'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        helperText={touched.email ? errors.email : ''}
                      />

                      <InputMask
                        mask='9999/99/99'
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        name='dateBirth'
                        value={values.dateBirth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.dateBirth && touched.dateBirth}
                        disabled={false}
                        alwaysShowMask
                      >
                        <TextInputField
                          label='Votre date de naissance AAAA/MM/JJ*'
                          helperText={touched.dateBirth ? errors.dateBirth : ''}
                        />
                      </InputMask>
                    </div>

                    <p>* Champs obligatoires</p>
                  </FieldsetContent>
                </Fieldset>

                <Fieldset>
                  <Legend>4. Préciser votre demande</Legend>

                  <TextAreaField
                    label={
                      <span
                        css={`
                          ${hideVisually}
                        `}
                      >
                        Description
                      </span>
                    }
                    name='description'
                    value={values.description}
                    rows='5'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Fieldset>

                <PrivacyPolicyCheckbox
                  name='hasConfirmed'
                  onChange={() => {
                    setFieldValue('hasConfirmed', !values.hasConfirmed);
                  }}
                  checked={values.hasConfirmed}
                  error={errors.hasConfirmed}
                />

                <Button
                  type='submit'
                  primary
                  outlined
                  loading={isSubmitting || isValidating}
                  disabled={isSubmitting || isValidating}
                >
                  Envoyer
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

ContactForm.propTypes = {
  id: PropTypes.string,
};

ContactForm.defaultProps = {
  id: undefined,
};

export default ContactForm;
