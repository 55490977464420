import React from 'react';
import PropTypes from 'prop-types';

import LogoSVG from '../../images/vector-logoMaxilloQuebec.svg';

import randomString from '../../utils/randomString';

import StyledModal, {
  ActionButton,
  Header,
  IconCross,
  LogoContainer,
  Logo,
  Stack,
  Wrapper,
} from './Modal.styles';

const actionButtonId = randomString();

const Modal = ({ isOpen, onDismiss, onClose, children, ...props }) => {
  return (
    <StyledModal isOpen={isOpen} onRequestClose={onDismiss} {...props}>
      <Wrapper>
        <Stack>
          <Header>
            <LogoContainer>
              <p>
                <Logo src={LogoSVG} alt='Maxillo Québec' />
              </p>
            </LogoContainer>

            <ActionButton onClick={onClose} aria-labelledby={actionButtonId}>
              <span id={actionButtonId}>Fermer</span>
              <IconCross aria-hidden='true' focusable='false' />
            </ActionButton>
          </Header>
          {children}
        </Stack>
      </Wrapper>
    </StyledModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  onClose: PropTypes.func,
};
Modal.defaultProps = {
  onDismiss: () => {},
  onClose: () => {},
};

export default Modal;
