// vendors
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { rem, em, rgba } from 'polished';
import colors from '../../styles/colors';
import { greaterThan, between, lessThan } from '../../utils/mediaQuery';
import { speed, easing } from '../../styles/animation';
import { fontWeights } from '../../styles/typography';
import zIndices from '../../styles/zIndices';
import breakpoints from '../../styles/breakpoints';

// export const selfBreakpoints = {
//   xs: 576,
//   sm: 768,
//   md: 992,
//   lg: 1200,
// };
export const selfBreakpoints = {
  xs: breakpoints[0],
  sm: breakpoints[2],
  md: breakpoints[4],
  lg: breakpoints[6],
};

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;

  z-index: ${zIndices.sticky};

  width: 100vw;
`;

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: grid;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;

  color: ${colors.white};

  ${lessThan(selfBreakpoints.sm)} {
    grid-template-rows: 72px auto 1fr 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);

    height: ${(props) => (props.open ? '100vh' : rem(72))};

    background-color: ${(props) =>
      props.open ? rgba(colors.azure, 1) : rgba(colors.azure, 0)};

    transition: height ${speed.default}, background-color ${speed.fast};

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  ${greaterThan(selfBreakpoints.sm)} {
    grid-template-columns: auto auto;
    justify-content: space-between;

    height: ${rem(110)};
    padding-left: ${rem(40)};

    background-color: ${colors.azure};
  }

  ${greaterThan(selfBreakpoints.md)} {
    grid-template-columns: auto 1fr;
    justify-content: stretch;
  }
`;

export const StyledNav = styled.nav`
  display: grid;

  ${lessThan(selfBreakpoints.sm)} {
    grid-auto-flow: row;
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
    row-gap: ${rem(15)};

    margin-top: 15px;
  }

  ${greaterThan(selfBreakpoints.sm)} {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;

    grid-template-rows: auto auto;
    grid-template-columns: repeat(4, auto) auto;
    align-items: end;
    height: ${rem(110)};

    margin-left: ${rem(40)};

    column-gap: ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    grid-template-columns: 1fr auto auto;
    justify-content: center;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;

  font-weight: ${fontWeights.medium};
  font-size: ${em(25, 15)};
  line-height: 1.15;
  text-align: center;
  text-decoration: none;
  word-break: normal;

  ::after {
    display: block;

    width: 100%;
    height: 2px;

    margin-top: 2px;

    background-color: currentColor;

    transform: scaleX(0);
    transform-origin: right;

    transition: transform ${speed.fast} ${easing.outback};

    content: '';

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  &.active,
  :hover,
  :focus {
    ::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  ${greaterThan(selfBreakpoints.sm)} {
    font-size: ${em(21, 19)};
  }
`;

export const NavList = styled.ul`
  display: grid;

  ${lessThan(selfBreakpoints.sm)} {
    grid-auto-flow: row;
    row-gap: 15px;

    margin: 0 ${rem(60)};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    grid-auto-flow: column;
    align-items: end;
    justify-content: end;

    margin: 0 0 0;
    margin-bottom: ${rem(32)};

    column-gap: ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    justify-content: center;
  }
`;

export const NavListItem = styled.li``;

export const PrimaryNavList = styled(NavList)`
  ${StyledLink} {
    color: ${colors.white};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    grid-row: 2 / span 1;
    grid-column: 1 / span 4;
  }

  ${between(selfBreakpoints.sm, selfBreakpoints.md)} {
    grid-template-columns: subgrid;
  }

  ${greaterThan(selfBreakpoints.md)} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;
  }
`;

export const SecondaryNavList = styled(NavList)`
  ${StyledLink} {
    color: ${colors.mineShaft};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    grid-row: 1 / span 1;
    grid-column: 2 / span 3;
  }

  ${between(selfBreakpoints.sm, selfBreakpoints.md)} {
    justify-content: space-between;
    margin-bottom: 0 !important;
  }

  ${greaterThan(selfBreakpoints.md)} {
    grid-row: 1 / span 2;
    grid-column: 2 / span 1;
  }

  /**
    Hack for forcing link to go on 2 lines
   */
  ${between(selfBreakpoints.md, 1100)} {
    grid-auto-columns: min-content;
  }

  ${between(selfBreakpoints.lg, 1262)} {
    grid-auto-columns: min-content;
  }
`;

export const MenuButtonWrapper = styled.div`
  display: flex;

  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  align-items: center;
  justify-content: flex-end;

  /* TODO: Create wrapper helper */
  padding-right: ${rem(28)};

  background-color: ${colors.azure};

  button {
    margin-right: ${rem(-11)};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    display: none;
  }
`;

export const LogoSection = styled.div`
  display: flex;

  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  justify-content: flex-start;

  ${lessThan(selfBreakpoints.sm)} {
    /* TODO: Create wrapper helper */
    align-items: center;
    padding-left: ${rem(28)};

    background-color: ${colors.azure};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    align-items: flex-end;
  }
`;

export const LogoWrapperStyle = css`
  display: block;
  margin: 0;

  line-height: 0;

  ${greaterThan(selfBreakpoints.sm)} {
    margin-bottom: ${rem(40)};
  }
`;

export const Logo = styled.img`
  height: ${rem(20)};

  /* width: 40px; */

  /* ${greaterThan(selfBreakpoints.sm)} {
    height: ${rem(28)};
  } */
`;

export const CTALink = styled(Link)`
  display: block;
  padding: ${rem(20)} ${rem(60)} ${rem(26)};

  color: ${colors.mineShaft};

  font-weight: ${fontWeights.medium};
  font-size: ${em(25, 15)};
  text-decoration: none;
  word-break: normal;

  background-color: ${colors.azure200};

  transition: background-color ${speed.fast};

  :hover,
  :focus,
  &.active {
    background-color: ${colors.azure100};
  }

  ${greaterThan(selfBreakpoints.sm)} {
    display: flex;
    grid-row: 1 / span 2;
    grid-column: 5 / span 1;
    align-items: flex-end;
    align-self: stretch;

    padding: ${rem(40)} ${rem(40)} ${rem(32)};

    font-size: ${em(21, 19)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    grid-row: 1 / span 2;
    grid-column: 3 / span 1;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const HeaderSpacer = styled.div`
  height: ${rem(72)};

  ${greaterThan(selfBreakpoints.sm)} {
    height: ${rem(110)};
  }
`;

export const FormButtonContainer = styled.div`
  display: flex;
  grid-row: 3 / span 1;
  grid-column: 1 / span 2;
  align-items: center;
  justify-content: stretch;

  padding: 0 ${rem(28)};

  button {
    min-width: 100%;
    max-width: none;

    font-weight: ${fontWeights.medium};

    svg {
      height: 1.8em;

      transform: rotate(50deg);
    }
  }

  ${greaterThan(selfBreakpoints.sm)} {
    position: absolute;
    top: ${rem(248)};
    right: calc(100% + 2px);

    padding: 0;

    word-break: keep-all;

    transform: rotate(-90deg);
    transform-origin: top right;
  }
`;

export const CartButtonContainer = styled.div`
  display: flex;
  grid-row: 4 / span 1;
  grid-column: 1 / span 2;
  align-items: center;
  justify-content: stretch;

  padding: 0 ${rem(28)};

  button {
    min-width: 100%;
    max-width: none;

    font-weight: ${fontWeights.medium};

    svg {
      height: 1.8em;
    }
  }

  ${greaterThan(selfBreakpoints.sm)} {
    position: absolute;
    top: ${rem(248)};
    right: 0;

    padding: 0;

    word-break: keep-all;

    transform: rotate(-90deg) translateY(calc(-100% + 2px));
    transform-origin: top right;
  }
`;
