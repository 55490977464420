import React from 'react';
import PropTypes from 'prop-types';
import { GlobalStyle } from '../../styles/global';
import Header from '../Header';

import '@csstools/normalize.css';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import Footer from '../Footer';
import ContactForm from '../ContactForm';

const Layout = ({ children, location, hasNoHeaderSpacer, backgroundColor }) => {
  return (
    <>
      <GlobalStyle backgroundColor={backgroundColor} />

      <Header location={location} hasNoSpacer={hasNoHeaderSpacer} />

      <main>{children}</main>

      <Footer />

      <ContactForm />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasNoHeaderSpacer: PropTypes.bool,
  backgroundColor: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Layout.defaultProps = {
  hasNoHeaderSpacer: false,
  backgroundColor: undefined,
  location: {
    pathname: undefined,
  },
};

export default Layout;
