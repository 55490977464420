// vendors
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { greaterThan, between } from '../../../utils/mediaQuery';
import { selfBreakpoints } from '../Header.styles';

export const LogoWrapperStyle = css`
  display: block;
  margin: 0;

  line-height: 0;

  ${greaterThan(selfBreakpoints.sm)} {
    margin-bottom: ${rem(40)};
  }
`;

export const ImgConcat = styled.img`
  display: none;
  height: ${rem(20)};

  ${between(selfBreakpoints.sm, selfBreakpoints.lg)} {
    display: block;
  }
`;

export const ImgFull = styled.img`
  display: block;
  height: ${rem(20)};

  ${between(selfBreakpoints.sm, selfBreakpoints.lg)} {
    display: none;
  }
`;
