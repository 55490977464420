import styled from 'styled-components';
import { em } from 'polished';

import ReactModalAdapter from './ReactModalAdapter';
import theme from '../../styles/theme';
import { lessThan, greaterThan } from '../../utils/mediaQuery';
import breakpointsRange from '../../utils/breakpointsRange';
import { speed } from '../../styles/animation';
import zIndices from '../../styles/zIndices';

import IconPlus from '../../images/IconPlus';

export const Wrapper = styled.div`
  max-width: 1360px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  transform: translate3d(0, -15px, 0);
  opacity: 0;

  transition: transform ${speed.default}, opacity ${speed.default};

  will-change: transform, opacity;

  .Content[class*='--after-open'] & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .Content[class*='--before-close'] & {
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h2 {
    margin: 0;

    line-height: 0;
  }
`;

export const Logo = styled.img`
  ${breakpointsRange(
    [{ prop: 'height', sizes: [20, 30] }],
    theme.breakpoints.fonts
  )};

  opacity: 0.66;
`;

export const IconCross = styled(IconPlus)`
  transform: rotate(45deg);
`;

export const ActionButton = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpointsRange(
    [{ prop: 'width', sizes: [27, 34] }],
    theme.breakpoints.fonts
  )};

  ${breakpointsRange(
    [{ prop: 'height', sizes: [27, 34] }],
    theme.breakpoints.fonts
  )};

  margin: 0;
  padding: 0;

  color: ${theme.colors.primary};
  line-height: 0;

  background: none;

  border: 0;
  cursor: pointer;

  appearance: none;

  :focus {
    outline: 0;
  }

  ::before {
    position: absolute;

    width: calc(100% + 20px);
    height: calc(100% + 20px);

    border: 3px solid;
    border-radius: 50%;
    transform: scale(1.5);

    cursor: default;

    opacity: 0;

    transition: transform ${speed.fast}, opacity ${speed.fast};

    content: '';

    will-change: transform, opacity;

    ${lessThan(theme.breakpoints.fonts[1])} {
      display: none;
    }
  }

  :focus::before {
    transform: scale(1);

    opacity: 1;
  }

  span {
    position: absolute;

    word-break: keep-all;

    transform: translateX(-48px);
    opacity: 0;

    transition: transform ${speed.fast}, opacity ${speed.fast};
    transition-delay: ${speed.fast};

    will-change: transform, opacity;

    ${lessThan(theme.breakpoints.fonts[1])} {
      display: none;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  :hover span,
  :focus span {
    transform: translateX(-70px);
    opacity: 1;
  }

  svg {
    ${breakpointsRange(
      [{ prop: 'width', sizes: [27, 34] }],
      theme.breakpoints.fonts
    )};

    ${breakpointsRange(
      [{ prop: 'height', sizes: [27, 34] }],
      theme.breakpoints.fonts
    )};
  }
`;

export const Stack = styled.div`
  ${theme.layouts.stack([50, 84], theme.breakpoints.fonts)}
`;

const StyledModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Content',
  bodyOpenClassName: 'Modal--open',
})`
  .Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: ${zIndices.modal};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    background-color: ${theme.colors.mineShaft500};

    opacity: 0;

    transition: opacity 500ms;

    will-change: transform, opacity;

    ::before,
    ::after {
      position: absolute;

      width: calc(100% + 15px);
      height: calc(100% + 15px);

      border: ${em(24)} solid ${theme.colors.white};

      transition: transform 500ms;

      content: '';

      pointer-events: none;

      @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    ::before {
      top: 0;
      left: 0;

      border-right-width: 0;
      border-bottom-width: 0;

      transform: translate3d(-15px, -15px, 0);
    }

    ::after {
      right: 0;
      bottom: 0;

      border-top-width: 0;
      border-left-width: 0;

      transform: translate3d(15px, 15px, 0);
    }

    &[class*='--after-open'] {
      opacity: 1;
    }

    &[class*='--before-close'] {
      opacity: 0;
    }

    &[class*='--after-open']::before,
    &[class*='--after-open']::after {
      transform: translate3d(0, 0, 0);
    }

    &[class*='--before-close']::before {
      transform: translate3d(-15px, -15px, 0);
    }

    &[class*='--before-close']::after {
      transform: translate3d(15px, 15px, 0);
    }

    ${lessThan(theme.breakpoints.fonts[0])} {
      ::before,
      ::after {
        border-width: 0.5em;
      }

      ::before {
        transform: translate3d(-6px, -6px, 0);
      }

      ::after {
        transform: translate3d(6px, 6px, 0);
      }

      &[class*='--before-close']::before {
        transform: translate3d(-6px, -6px, 0);
      }

      &[class*='--before-close']::after {
        transform: translate3d(6px, 6px, 0);
      }
    }
  }

  .Content {
    width: calc(100vw - 3em);
    height: calc(100vh - 3em);
    padding: ${em(24)};

    ${lessThan(theme.breakpoints.fonts[0])} {
      width: calc(100vw - 1em);
      height: calc(100vh - 1em);
    }

    ${greaterThan(theme.breakpoints.fonts[0])} {
      padding: ${em(48)} ${em(32)};
    }

    ${greaterThan(theme.breakpoints.fonts[1])} {
      padding: ${em(64)} ${em(32)};
    }

    overflow-x: hidden;
    overflow-y: auto;

    color: ${theme.colors.brownGrey};

    :focus {
      outline: 0;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    .Overlay {
      transition: none;
    }
  }
`;

export default StyledModal;
