// vendors
import React from 'react';

const IconFacebook = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' {...props}>
    <path d='M30 3.2v23.6a3.2 3.2 0 01-3.2 3.2h-5.7V18.1h4l.6-4.5h-4.6v-2.9c0-1.3.3-2.2 2.2-2.2h2.4v-4a33.5 33.5 0 00-3.5-.2c-3.4 0-5.8 2.1-5.8 6v3.4h-4v4.5h4V30H3.2A3.2 3.2 0 010 26.8V3.2A3.2 3.2 0 013.2 0h23.6A3.2 3.2 0 0130 3.2z' />
  </svg>
);

export default IconFacebook;
