// vendors
import React from 'react';

const IconPlus = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 11.26 11.26'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <line className='cls-1' x1='5.63' x2='5.63' y2='11.26' />
    <line className='cls-1' x1='11.26' y1='5.63' y2='5.63' />
  </svg>
);

export default IconPlus;
