import styled from 'styled-components';
import { em } from 'polished';

import theme from '../../styles/theme';
import breakpointsRange from '../../utils/breakpointsRange';
import { greaterThan } from '../../utils/mediaQuery';
import { speed } from '../../styles/animation';

const Container = styled.div`
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;

  ${greaterThan(theme.breakpoints.fonts[0])} {
    padding: 0 ${em(24)};
  }

  ${breakpointsRange(
    [{ prop: 'marginBottom', sizes: [45, 70] }],
    theme.breakpoints.fonts
  )};

  transform: translate3d(0, -15px, 0);
  opacity: 0;

  transition: transform ${speed.default}, opacity ${speed.default};

  will-change: transform, opacity;

  .Content[class*='--after-open'] & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .Content[class*='--before-close'] & {
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export default Container;
