// vendors
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useContactForm } from '../../contexts/contactFormContext';
import { useSnipcart } from '../../contexts/snipcartContext';
import {
  StyledHeader,
  Container,
  StyledNav,
  PrimaryNavList,
  StyledLink,
  SecondaryNavList,
  MenuButtonWrapper,
  LogoSection,
  NavListItem,
  CTALink,
  HeaderSpacer,
  FormButtonContainer,
  CartButtonContainer,
} from './Header.styles';

import BurgerButton from '../BurgerButton';
import Button from '../Button';

import IconPen from '../../images/IconPen';
import Logo from './Logo';
import IconCart from '../../images/IconCart';

const navItemPrimary = [
  {
    id: 'cliniques',
    label: 'Cliniques',
    slug: '/nos-cliniques',
  },
  {
    id: 'equipe',
    label: 'Équipe',
    slug: '/equipe',
  },
  {
    id: 'services',
    label: 'Services',
    slug: '/services',
  },
  {
    id: 'contact',
    label: 'Contact',
    slug: '/contact',
  },
];

const navItemSecondary = [
  {
    id: 'espace-patient',
    label: 'Espace patient',
    slug: '/espace-patient',
  },
  {
    id: 'espace-pro',
    label: 'Espace pro',
    slug: '/espace-professionnel',
  },
];

const Header = ({ hasNoSpacer, location }) => {
  const isHomePage = !!location.pathname && location.pathname === '/';
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const { open: openContactForm } = useContactForm();

  const snipcart = useSnipcart();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleContactForm = () => {
    setOpen(false);

    openContactForm();
  };

  const handleOpenCart = () => {
    window.Snipcart.api.theme.cart.open();
  };

  const updateCartCountState = useCallback(
    (count) => setCartItemsCount(count),
    [setCartItemsCount]
  );

  useEffect(() => {
    if (snipcart) {
      updateCartCountState(snipcart.store.getState().cart.items.count);

      const unsubscribeStoreEvent = snipcart.store.subscribe(() =>
        updateCartCountState(snipcart.store.getState().cart.items.count)
      );

      return () => unsubscribeStoreEvent();
    }

    return () => {};
  }, [snipcart, updateCartCountState]);

  return (
    <>
      <StyledHeader>
        <Container open={open}>
          <LogoSection>
            <Logo isHomePage={isHomePage} />
          </LogoSection>

          <StyledNav>
            <PrimaryNavList>
              {navItemPrimary.map((item) => (
                <NavListItem key={item.id}>
                  <StyledLink
                    to={item.slug}
                    activeClassName='active'
                    partiallyActive
                  >
                    {item.label}
                  </StyledLink>
                </NavListItem>
              ))}
            </PrimaryNavList>

            <SecondaryNavList>
              {navItemSecondary.map((item) => (
                <NavListItem key={item.id}>
                  <StyledLink
                    to={item.slug}
                    activeClassName='active'
                    partiallyActive
                  >
                    {item.label}
                  </StyledLink>
                </NavListItem>
              ))}
            </SecondaryNavList>

            <CTALink to='/formations' activeClassName='active' partiallyActive>
              Formations
            </CTALink>
          </StyledNav>

          <FormButtonContainer>
            <Button
              outlined
              renderIcon={<IconPen />}
              onClick={handleContactForm}
            >
              Formulaire
            </Button>
          </FormButtonContainer>

          {cartItemsCount > 0 && (
            <CartButtonContainer>
              <Button
                outlined
                renderIcon={<IconCart count={cartItemsCount} />}
                onClick={handleOpenCart}
              >
                Panier
              </Button>
            </CartButtonContainer>
          )}

          <MenuButtonWrapper>
            <BurgerButton open={open} onClick={handleClick} />
          </MenuButtonWrapper>
        </Container>
      </StyledHeader>

      {!hasNoSpacer && <HeaderSpacer />}
    </>
  );
};

Header.propTypes = {
  hasNoSpacer: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Header.defaultProps = {
  hasNoSpacer: true,
  location: {
    pathname: undefined,
  },
};

export default Header;
