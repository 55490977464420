// vendors
import React from 'react';
import PropTypes from 'prop-types';

import { Bread, Crust, StyledButton } from './BurgerButton.styles';

const BurgerButton = ({ onClick, open }) => {
  return (
    <StyledButton onClick={onClick} open={open}>
      <Bread style={{ transform: `rotate(${open ? '45deg' : '0'})` }}>
        <Crust style={{ transform: `translateY(${open ? '0' : '7px'})` }} />
      </Bread>

      <Bread style={{ opacity: open ? 0 : 1 }}>
        <Crust />
      </Bread>

      <Bread style={{ transform: `rotate(${open ? '-45deg' : '0'})` }}>
        <Crust style={{ transform: `translateY(${open ? '0' : '-7px'})` }} />
      </Bread>
    </StyledButton>
  );
};

BurgerButton.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

BurgerButton.defaultProps = {
  onClick: () => {},
  open: false,
};

export default BurgerButton;
