// vendors
import React from 'react';
import { Link } from 'gatsby';
import { hideVisually } from 'polished';
import CallToActionSection from './CallToActionSection';
import {
  Container,
  Wrapper,
  AddressBlock,
  AddressBlockTitle,
  ContactContainer,
  SocialsContainer,
  InternalLinksContainer,
} from './Footer.styles';
import Button from '../Button';
import IconFacebook from '../../images/IconFacebook';
import IconInstagram from '../../images/IconInstagram';
import { useContactForm } from '../../contexts/contactFormContext';

const Footer = () => {
  const { open } = useContactForm();

  const handleOpenContactForm = () => {
    open();
  };
  return (
    <>
      <CallToActionSection />

      <Container>
        <Wrapper>
          <AddressBlock>
            <AddressBlockTitle>Clinique de Québec</AddressBlockTitle>

            <p>
              1125, boul. Lebourgneuf
              <br />
              bureau 200
              <br />
              Québec (Québec) G2K&nbsp;0J2
            </p>

            <p>
              info@
              <wbr />
              maxilloquebec.com
              <br />
              418-624-0088 poste 1
            </p>
          </AddressBlock>

          <AddressBlock>
            <AddressBlockTitle>Clinique de Lévis</AddressBlockTitle>

            <p>
              1200, boul. Guillaume-Couture
              <br />
              bureau 302
              <br />
              Lévis (Québec) G6W&nbsp;0R9
            </p>

            <p>
              info.levis@
              <wbr />
              maxilloquebec.com
              <br />
              418-624-0088 poste 2
            </p>
          </AddressBlock>

          <SocialsContainer>
            <a
              href='https://www.facebook.com/maxilloquebec'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span css={hideVisually}>
                Lien vers le profil facebook de maxillo québec
              </span>
              <IconFacebook />
            </a>

            <a
              href='https://www.instagram.com/cliniquemaxilloquebec/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span css={hideVisually}>
                Lien vers le profil instagram de maxillo québec
              </span>
              <IconInstagram />
            </a>
          </SocialsContainer>

          <InternalLinksContainer>
            <Link to='/politique-de-confidentialite'>
              Politique de confidentialité
            </Link>
          </InternalLinksContainer>

          <ContactContainer>
            <p>
              Pour toute question sur nos services, contactez-nous par courriel.
            </p>

            <Button onClick={handleOpenContactForm} outlined primary>
              Prendre contact
            </Button>
          </ContactContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default Footer;
