// vendors
import styled from 'styled-components';
import { em } from 'polished';
import colors from '../../styles/colors';
import breakpointsRange from '../../utils/breakpointsRange';
import breakpoints from '../../styles/breakpoints';
import { fontSizes } from '../../styles/typography';
import { h2Style, withFrontUnderline } from '../../styles/global';
import { greaterThan } from '../../utils/mediaQuery';

export const Container = styled.footer`
  color: ${colors.white};

  background-color: ${colors.mineShaft};

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [28, 104] },
      { prop: 'paddingRight', sizes: [28, 40, 40, 0] },
      { prop: 'paddingBottom', sizes: [28, 104] },
      { prop: 'paddingLeft', sizes: [28, 40, 40, 0] },
    ],
    breakpoints.spacings
  )};
`;

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 56px;
  grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));

  max-width: 1440px;

  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [36, 88] },
      { prop: 'paddingRight', sizes: [28, 28, 40, 40] },
      { prop: 'paddingBottom', sizes: [36, 64] },
      { prop: 'paddingLeft', sizes: [28, 28, 40, 40] },
      { prop: 'gridColumnGap', sizes: [28, undefined, 64, 96] },
    ],
    breakpoints.spacings
  )};
`;

export const SocialsContainer = styled.div`
  grid-row: span 1;

  a {
    display: inline-block;
    width: ${em(30, 19)};

    margin: 0 1em 0 0;

    color: ${colors.azure};
  }

  ${greaterThan(breakpoints[1])} {
    grid-row: 3 / span 1;
  }

  ${greaterThan(breakpoints[4])} {
    grid-row: 2 / span 1;
    grid-column: span 2;
  }
`;

export const InternalLinksContainer = styled.div`
  grid-row: span 1;

  a {
    display: inline-block;
    margin: 0 1em 0 0;
    text-underline-offset: 4px;

    color: ${colors.azure};

    :hover,
    :focus {
      text-decoration: none;
    }
  }

  ${greaterThan(breakpoints[1])} {
    grid-row: 4 / span 1;
  }

  ${greaterThan(breakpoints[4])} {
    grid-row: 3 / span 1;
    grid-column: span 2;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-flow: column;
  grid-row: span 1;
  grid-column: span 1;
  justify-content: space-between;

  p {
    ${h2Style};
    ${withFrontUnderline};

    margin-top: 0;
  }

  ${greaterThan(breakpoints[1])} {
    grid-column: span 2;
  }

  ${greaterThan(breakpoints[4])} {
    grid-row: 1 / span 2;
    grid-column: span 1;
  }

  ${greaterThan(breakpoints[5])} {
    grid-column: span 2;
  }
`;

export const AddressBlock = styled.address`
  grid-column: span 1;

  font-style: inherit;

  word-wrap: initial;
`;

export const AddressBlockTitle = styled.p`
  margin-top: 0;

  font-size: ${30 / 19}em;

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [20, 24, 30],
        bases: fontSizes.body,
        unit: 'em',
      },
    ],
    breakpoints.fonts
  )}
`;
